import consumer from './consumer';

if (document.querySelector('[data-cable-check]')) {
  const dashboardChannel = consumer.subscriptions.create('StatusChannel', {
    connected: () => {
      dashboardChannel.check();
    },
    disconnected: () => {
      document.querySelector('[data-cable-check]').textContent = '👎';
    },
    rejected: () => {
      document.querySelector('[data-cable-check]').textContent = '👎';
    },
    received: (data) => {
      document.querySelector('[data-cable-check]').textContent = data;
    },
    check() {
      this.perform('check', { message: 'check' });
    },
  });
}
