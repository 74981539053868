import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  dispose() {
    const tooltip = bootstrap.Tooltip.getInstance(this.element);
    tooltip.dispose();
  }
}
