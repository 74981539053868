import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['textArea', 'textLength'];
  }

  calculateRemaining() {
    const maxlength = this.data.get('maxlength');
    this.textLengthTarget.textContent = `${maxlength - this.textAreaTarget.value.length} / ${maxlength}`;
  }
}
