import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['input', 'button', 'feedback', 'program', 'programInput'];
  }

  validateInput() {
    const length = this.data.get('length') || 3;
    const regex = new RegExp(`^([^ ]{${length},}| )*$`);
    if (regex.test(this.inputTarget.value)) {
      this.inputTarget.classList.remove('is-invalid');
      this.feedbackTarget.classList.remove('text-danger');
      this.feedbackTarget.classList.add('text-muted');
      this.buttonTarget.disabled = false;
    } else {
      this.inputTarget.classList.add('is-invalid');
      this.feedbackTarget.classList.add('text-danger');
      this.feedbackTarget.classList.remove('text-muted');
      this.buttonTarget.disabled = true;
    }
  }

  selectProgram(event) {
    this.programInputTarget.value = event.currentTarget.dataset.program;
    this.programTargets.forEach((button) => button.classList.remove('active'));
    event.currentTarget.classList.add('active');
    this.formTarget.submit();
  }
}
