import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['dropdown', 'input'];
  }

  toggleInput() {
    if (this.dropdownTarget.value === 'other') {
      this.inputTarget.classList.remove('d-none');
    } else {
      this.inputTarget.classList.add('d-none');
      this.inputTarget.value = '';
    }
  }
}
