import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as bootstrap from 'bootstrap/dist/js/bootstrap';
import '../controllers';
import '@github/time-elements';
import '../channels';
import '../src/application_form_validator';
import '../src/turbo_scroll_persistence';
import '../images';
import './styles.scss';
import { Turbo } from '@hotwired/turbo-rails';

import '../src/bootstrap_initializers';
import '../src/rails_ujs_turbo_polyfill';

require('@rails/ujs').start();

Turbo.start();
window.Turbo = Turbo;
window.bootstrap = bootstrap;
