import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  connect() {
    this.myModalElement = null;
    this.modal = null;
    this.tokenUuid = this.data.get('token-uuid');
    this.couponId = this.data.get('coupon-id');

    this.myModalElement = document.getElementById('wallet-card-form');
    this.modal = bootstrap.Modal.getInstance(this.myModalElement) || new bootstrap.Modal(this.myModalElement);
  }

  show() {
    if (this.couponId) {
      this.hideCouponList();
      this.path = `coupons/${this.couponId}`;
    } else {
      this.path = `tokens/${this.tokenUuid}`;
    }

    this.loadModal();
    this.modal.show();
  }

  hideCouponList() {
    const element = document.getElementById('coupons-list');
    const couponListModal = bootstrap.Modal.getInstance(element) || new bootstrap.Modal(element);
    couponListModal.hide();
  }

  loadModal() {
    Rails.ajax({
      type: 'get',
      url: `/crm/${this.path}/pass_kit_cards/new.js?language=${Loyalty.shortLocale}`,
    });
  }
}
