import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import consumer from '../channels/consumer';

export default class extends Controller {
  connect() {
    const _controller = this;
    this.myModalElement = null;
    this.modal = null;
    this.userId = this.data.get('user-id');
    this.piggyBankId = this.data.get('piggy-bank-id');

    this.myModalElement = document.getElementById('earn-and-burn-book');
    this.modal = bootstrap.Modal.getInstance(this.myModalElement) || new bootstrap.Modal(this.myModalElement);
    this.myModalElement.addEventListener('shown.bs.modal', (e) => {
      _controller.subscription = consumer.subscriptions.create({
        channel: 'EarnAndBurnBookChannel', piggy_bank_id: _controller.piggyBankId,
      }, {
        received(data) {
          _controller.loadBookEntries();
        },
      });
    });
    this.myModalElement.addEventListener('hidden.bs.modal', (e) => {
      consumer.subscriptions.remove(_controller.subscription);
    });
  }

  show() {
    this.loadBookEntries();
    this.modal.show();
  }

  loadBookEntries() {
    const params = `user_id=${this.userId}&language=${Loyalty.shortLocale}`;
    Rails.ajax({
      type: 'get',
      url: `/crm/piggy_banks/${this.piggyBankId}/earn_and_burn_book_entries.js?${params}`,
    });
  }
}
