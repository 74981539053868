import { Controller } from '@hotwired/stimulus';
import CableReady from 'cable_ready';
import consumer from '../channels/consumer';

export default class extends Controller {
  connect() {
    // this makes the controller globally available, to disable toasts "from the outside"
    window.crmUserController = this;

    // if a parameter open-modal-id has been passed, we schedule a check to see if we can open that modal
    if (this.data.get('open-modal-id')) {
      this.intervalReference = 0;
      this.intervalReference = setInterval(this.checkOpenedModal, 100, this);
    }

    const userId = this.data.get('user-id');
    this.toastContainer = document.getElementById('toasts-container');

    // subscribing to the channel, an alert will be shown to the operator if the data change
    this.subscription = consumer.subscriptions.create({ channel: 'UserUpdateChannel', user_id: userId }, {
      received(data) {
        if (data.cableReady) CableReady.perform(data.operations);
        this.showToasts();
      },

      showToasts() {
        const toastElList = [].slice.call(document.querySelectorAll('.toast'));
        toastElList.forEach((toastElement) => {
          const toast = new bootstrap.Toast(toastElement, { autohide: false });
          toast.show();
        });

        document.querySelector('[data-turbo-scroll]').addEventListener('click', () => {
          window.saved_scroll = document.scrollingElement.scrollTop;
        });
      },
    });

    // this avoids refresh notifications on this page to be triggered.
    document.documentElement.addEventListener('ajax:beforeSend', () => {
      this.disableToasts();
    });
    document.documentElement.addEventListener('ajax:complete', (event, data) => {
      this._restoreToasts();
    });

    document.documentElement.addEventListener('turbo:submit-start', () => {
      this.disableToasts();
    });

    document.documentElement.addEventListener('turbo:submit-end', () => {
      this._restoreToasts();
    });
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription);
  }

  disableToasts() {
    this._hide(this.toastContainer);
  }

  _restoreToasts() {
    setTimeout(() => {
      this.toastContainer.textContent = '';
      this._show(this.toastContainer, 'flex');
    }, 2000);
  }

  _show(field, mode = 'inline') {
    field.style.setProperty('display', mode, 'important');
  }

  _hide(field) {
    field.style.setProperty('display', 'none', 'important');
  }

  // we can only open the modal if the page finished loading and all turbo frames have finished loading their content
  checkOpenedModal(controller) {
    if (document.readyState === 'complete' && controller.allTurboFramesLoaded()) {
      clearInterval(controller.intervalReference);
      controller.openModal();
    }
  }

  openModal() {
    const element = document.getElementById(this.data.get('open-modal-id'));
    if (element) {
      const modal = bootstrap.Modal.getInstance(element) || new bootstrap.Modal(element);
      modal.show();
    }
  }

  allTurboFramesLoaded() {
    return [...document.getElementsByTagName('turbo-frame')].every((el) => el.complete);
  }
}
