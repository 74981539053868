import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static get targets() {
    return ['turboFrame'];
  }

  connect() {
    this.element.addEventListener('show.bs.modal', (event) => {
      const { src } = this.turboFrameTarget;
      this.turboFrameTarget.src = null;
      this.turboFrameTarget.loading = 'eager';
      this.turboFrameTarget.src = src;
    });
  }
}
