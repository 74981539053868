import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  connect() {
    this.myModalElement = null;
    this.modal = null;
    this.tokenUuid = this.data.get('token-uuid');

    this.myModalElement = document.getElementById('token-versions-list');
    this.modal = bootstrap.Modal.getInstance(this.myModalElement) || new bootstrap.Modal(this.myModalElement);
  }

  show() {
    this.loadTokenVersionList();
    this.modal.show();
  }

  loadTokenVersionList() {
    Rails.ajax({
      type: 'get',
      url: `/crm/tokens/${this.tokenUuid}/token_versions.js?language=${Loyalty.shortLocale}`,
    });
  }
}
