import Rails from '@rails/ujs';
import Plugin from '../vendor/formvalidation/es6/core/Plugin';

export default class RailsSubmitButton extends Plugin {
  constructor(opts) {
    super(opts);
    this.isFormValid = false;
    this.buttonClickHandler = this.handleClickEvent.bind(this);
  }

  install() {
    const form = this.core.getFormElement();
    this.submitButtons = [].slice.call(form.querySelectorAll('[type="submit"]'));
    this.submitButtons.forEach((button) => {
      button.addEventListener('click', this.buttonClickHandler);
    });
  }

  handleClickEvent(e) {
    const target = e.currentTarget;
    if (target.dataset.disableWith !== undefined) {
      this.core.validate().then((result) => {
        if (result === 'Valid') {
          // not the best solution, but that controller is responsible for toasts
          if (window.crmUserController) {
            window.crmUserController.disableToasts();
          }
          Rails.disableElement(target);
        }
      });
    }
  }
}
