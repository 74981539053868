import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  connect() {
    this.postingId = this.data.get('posting-id');
    this.userId = this.data.get('user-id');

    let element = document.getElementById('earn-and-burn-book');
    this.earnAndBurnBookModal = bootstrap.Modal.getInstance(element) || new bootstrap.Modal(element);

    element = document.getElementById('create-reservation');
    this.cancelCashbackModal = bootstrap.Modal.getInstance(element) || new bootstrap.Modal(element);
  }

  show() {
    this.loadCancelCashbackForm();
    this.earnAndBurnBookModal.hide();
    this.cancelCashbackModal.show();
  }

  loadCancelCashbackForm() {
    const params = `posting_id=${this.postingId}&user_id=${this.userId}&language=${Loyalty.shortLocale}`;
    Rails.ajax({
      type: 'get',
      url: `/crm/reservations/new.js?${params}`,
    });
  }
}
