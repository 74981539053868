import formValidation from '../vendor/formvalidation/es6/core/Core';
import Declarative from '../vendor/formvalidation/es6/plugins/Declarative';
import DefaultSubmit from '../vendor/formvalidation/es6/plugins/DefaultSubmit';
import SubmitButton from '../vendor/formvalidation/es6/plugins/SubmitButton';
import RailsSubmitButton from './RailsSubmitButton';
import Trigger from '../vendor/formvalidation/es6/plugins/Trigger';
import Bootstrap from '../vendor/formvalidation/es6/plugins/Bootstrap';
import Icon from '../vendor/formvalidation/es6/plugins/Icon';
/* eslint-disable camelcase */
import en_US from '../vendor/formvalidation/es6/locales/en_US';
import it_IT from '../vendor/formvalidation/es6/locales/it_IT';
import fr_FR from '../vendor/formvalidation/es6/locales/fr_FR';
import de_DE from '../vendor/formvalidation/es6/locales/de_DE';
/* eslint-enable camelcase */

// validates the forms with formavalidation.io.
// the class RailsSubmitButton makes formvalidation.io and data-disable-with (rails_ujs) play nice together.
// this currently breaks turbo and therefore it cannot be applied in some cases.
const LANGUAGES = {
  en: en_US, de_ch: de_DE, it_ch: it_IT, fr_ch: fr_FR,
};
class ApplicationFormValidator {
  constructor() {
    const forms = document.querySelectorAll('form:not([data-remote]):not(.button_to):not([data-skip-formvalidation])');
    if (forms.length > 0) {
      forms.forEach((form) => {
        if (form.closest('turbo-frame') != null) {
          // eslint-disable-next-line no-console
          console.warn('Cannot apply formvalidation to',
            form,
            'because it is wrapped in a turbo frame. To hide this message add data-skip-formvalidation to the form.');
          return;
        }
        formValidation(
          form,
          {
            locale: Loyalty.locale,
            localization: LANGUAGES[Loyalty.locale],
            plugins: {
              trigger: new Trigger({
                event: 'change',
              }),
              railsSubmitButton: new RailsSubmitButton(),
              submitButton: new SubmitButton(),
              defaultSubmit: new DefaultSubmit(),
              declarative: new Declarative({
                html5Input: true,
              }),
              bootstrap: new Bootstrap(),
              icon: new Icon({
                valid: 'fa fa-check',
                invalid: 'far fa-exclamation-circle',
                validating: 'fa fa-refresh',
              }),
            },
            fields: {
              'user[password_confirmation]': {
                validators: {
                  identical: {
                    compare() {
                      return document.getElementById('user_password').value;
                    },
                  },
                },
              },
            },
          },
        );
      });
    }
  }
}
document.addEventListener('turbo:load', () => {
  new ApplicationFormValidator();
});
