import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['dropdown', 'input', 'container'];
  }

  toggleRequired() {
    if (this.dropdownTarget.value === this.data.get('optional-if')) {
      this.inputTarget.required = false;
      this.containerTarget.classList.add('form-optional');
    } else {
      this.inputTarget.required = true;
      this.containerTarget.classList.remove('form-optional');
    }
  }
}
